<script>
import MenuFieldForm from "@/views/menu/menu/menu-field/menuFieldForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    menuService: "menuService",
    fileUploadService: "fileUploadService",
    toastService: "toastService"
  }
})
export default class MenuFieldEdit extends mixins(MenuFieldForm) {
  formId = "edit-menu-field-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      this.model.linkData.hotspotOptions = "{}";
      this.model.linkData.localizations = [
        ...this.model.linkData.hotspotLocalizations,
      ];
      await this.menuService.updateField(this.model.idMenu, this.model.id, this.model);
      this.$router.push(`/menu-fields/${this.model.idMenu}/list`);
      this.toastService.success(this.translations.success.menuField_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.menuFields_edit;
  }

  async afterCreate () {
    this.model = await this.menuService.readField(this.$route.params.idParent, this.$route.params.id);
    this.isReady = true;
  }
}
</script>
