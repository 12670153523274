import LinkForm from "@/views/links/linkForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    menuService: "menuService"
  },
  watch: {
    "model.linkData.type": function (newVal, oldVal) {
      if (newVal != oldVal && oldVal != undefined) {
        this.model.to = null;
        this.model.toCode = null;
        this.model.externalLink = null;
        this.model.subType = null;
      }
      this.refreshToItems(newVal);
    }
  }
})
export default class MenuFieldForm extends mixins(LinkForm) {
  model = {};
  menu = {};
  events = [];
  icons = [];

  get iconsList() {
    return this.icons.map(i => {
      return {
        value: i,
        label: i
      };
    });
  }

  get linkTypesOptions() {
    const lto = this.linkTypes
    .filter(l => l?.linkTypeGroup != "NoGroup")
    .filter(l => l?.canBeInMenu)
    .map(l => {
      let groupLabel;

      switch(l?.linkTypeGroup) {
        case "Direct":
          groupLabel = this.translations?.labels?.link_form_type_direct;
          break;
        case "SpecificParameter":
          groupLabel = this.translations?.labels?.link_form_type_specificparameter;
          break;
        case "RelatedResource":
          groupLabel = this.translations?.labels?.link_form_type_relatedresource;
          break;
        case "Feature":
          groupLabel = this.translations?.labels?.link_form_type_feature;
          break;
        case "NoAction":
          groupLabel = this.translations?.labels?.link_form_type_noaction;
          break;
        default:
          groupLabel = this.translations?.labels?.link_form_type_nogroup;
      }

      return {
        value : l?.id,
        label : l?.id,
        group : groupLabel
      };

    });

    return lto;
  }

  get parentLanguages() {
    if (!this.menu.idEvent) {
      return [];
    }
    const idEvent = this.menu.idEvent;
    const event = this.events.filter(e => e.id == idEvent);
    if (event.length == 0) {
      return [];
    }
    return event[0].languages;
  }

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "code",
            label: this.translations.labels.menuField_form_code,
            type: "text",
            required: true
          },
          {
            id: "icon",
            label: this.translations.labels.menuField_form_icon,
            type: "select",
            items: this.iconsList
          },
          {
            id: "order",
            label: this.translations.labels.menuField_form_order,
            type: "number",
            rules: "gt:0|lte:255",
            required: true
          },
          {
            id: "localizations",
            type: "localizations",
            header: this.translations.labels.form_localizations,
            languages: this.parentLanguages,
            resetColumns: true,
            elements: [
              {
                id: "name",
                label: this.translations.labels.menuField_form_name,
                required: true,
                type: "text"
              }
            ],
          }
        ]
      },
      {
        id: "linkData",
        legend: this.translations.labels.menuField_form_linkData,
        type: "fieldset",
        group: "object",
        collapsible: true,
        resetColumns: true,
        elements: this.linkElements
      }
    ];
  }

  get currentTargetType() {
    if (!this.model?.linkData?.type) {
      return null;
    }

    const targetType = this.linkTypes.filter(t => t.id == this.model.linkData.type);
    if (targetType.length == 0) {
      return null;
    }

    return targetType[0];
  }

  get entityType() {
    return "Menu";
  }

  get helpLink() {
    return this.translations.pageHelp.menuField;
  }

  alwaysVisible() {
    return false;
  }

  async created() {
    this.icons = await this.menuService.icons();
    this.menu = await this.menuService.read(this.$route.params.idParent);
    this.events = await this.eventService.list();

    this.afterCreate();
  }
}
